import {
  fieldNamedValue,
  fromMonthYear,
  parseAgeGroup,
  parseInt,
  parseEndemicityLf,
  parseEndemicityOncho,
  parseNotTargetedStatus,
  parseSurveysLfTas,
  parseSurveysLfSsSc,
  parseSurveysOncho,
  parseYesNo, empty, parseDate
} from './lib';
import {
  JRSMParserConfig,
  JRSMParseResultField,
  ResultValue
} from './types';
import jrsm2018 from './jrsm2018';

// Differences from 2018
// Endemicity Status - 
//    19 - endemic, pending impact survey
// Columns moved on COUNTRY_INFO
// target_pop_village_oncho removed from IVM
export default {
  ...jrsm2018,
  request_district_data: {
    ...jrsm2018.request_district_data,
    fields: jrsm2018.request_district_data.fields.map(f => {
      switch (f.name) {
        case 'rounds_lf': return {...f, column: 'V'}
        case 'rounds_oncho': return {...f, column: 'W'}
        case 'surveys_lf_tas': return {...f, column: 'AA'}
        case 'surveys_lf_ss_sc': return {...f, column: 'AA'}
        case 'surveys_oncho': return {...f, column: 'AB'}
        case 'treatment_plan_lf': return {...f, column: 'E'}
        case 'treatment_plan_oncho': return {...f, column: 'F'}
        case 'target_population_lf': return {...f, column: 'G'}
        case 'target_population_oncho': return {...f, column: 'H'}
        case 'target_population_total': return {...f, mapper: (fields: JRSMParseResultField[]): ResultValue => {
          const target_population_lf = fieldNamedValue('target_population_lf', fields) || 0;
          const target_population_oncho = fieldNamedValue('target_population_oncho', fields) || 0;
          return target_population_lf > target_population_oncho ? target_population_lf : target_population_oncho;
        }}
        case 'pc_req_ivm_lf_only': return {...f, column: 'I'}
        case 'pc_req_ivm_oncho_only': return {...f, column: 'J'}
        case 'pc_req_ivm_lf_oncho': return {...f, column: 'K'}
        case 'pc_req_ivm_total': return {...f, column: 'L'}
        case 'ivm_stock_remaining': return {...f, column: 'M'}
        case 'pc_req_ivm_to_procure': return {...f, column: 'N'}
      }
      return f
    }).filter(f => f.name !== 'target_pop_village_oncho')
  },
  requests_ivm_data: {
    fields: [
      {
        name: 'ivm_required',
        sheet: /SUMMARY/,
        cell: 'D14',
        parser: parseInt,
        db: true
      },
      {
        name: 'ivm_in_stock',
        sheet: /SUMMARY/,
        cell: 'E14',
        parser: parseInt,
        db: true
      },
      {
        name: 'ivm_in_pipeline',
        sheet: /SUMMARY/,
        cell: 'F14',
        parser: parseInt,
        db: true
      },
      {
        name: 'ivm_requested',
        sheet: /SUMMARY/,
        cell: 'G14',
        parser: parseInt,
        db: true
      }
    ]
  },
  requests_to_treat_lf: {
    fields: [
      {
        name: 'people_to_treat_r1_lf',
        sheet: /SUMMARY/,
        cell: 'F33',
        parser: parseInt,
        db: true
      },
      {
        name: 'people_to_treat_r2_lf',
        sheet: /SUMMARY/,
        cell: 'G33',
        parser: parseInt,
        db: true
      }
    ]
  },
  requests_to_treat_oncho: {
    fields: [
      {
        name: 'people_to_treat_r1_oncho',
        sheet: /SUMMARY/,
        cell: 'F34',
        parser: parseInt,
        db: true
      },
      {
        name: 'people_to_treat_r2_oncho',
        sheet: /SUMMARY/,
        cell: 'G34',
        parser: parseInt,
        db: true
      }
    ]
  },
  requests_dates: {
    fields: [
      {
        name: 'r1_month',
        sheet: /SUMMARY/,
        cell: 'C44',
        db: false
      },
      {
        name: 'r1_year',
        sheet: /SUMMARY/,
        cell: 'D44',
        db: false
      },
      {
        name: 'r2_month',
        sheet: /SUMMARY/,
        cell: 'E44',
        db: false
      },
      {
        name: 'r2_year',
        sheet: /SUMMARY/,
        cell: 'F44',
        db: false
      },
      {
        name: 'arrive_month',
        sheet: /SUMMARY/,
        cell: 'G44',
        db: false
      },
      {
        name: 'arrive_year',
        sheet: /SUMMARY/,
        cell: 'H44',
        db: false
      },
      {
        name: 'mda1_date',
        db: true,
        sheet: /SUMMARY/,
        mapper: (fields: JRSMParseResultField[]): ResultValue => {
          const month = fieldNamedValue('r1_month', fields)
          const year = fieldNamedValue('r1_year', fields)
          return fromMonthYear(month, year)
        }
      },
      {
        name: 'mda2_date',
        db: true,
        sheet: /SUMMARY/,
        mapper: (fields: JRSMParseResultField[]): ResultValue => {
          const month = fieldNamedValue('r2_month', fields)
          const year = fieldNamedValue('r2_year', fields)
          return fromMonthYear(month, year)
        }
      },
      {
        name: 'shipment_arrive_by_date',
        db: true,
        sheet: /SUMMARY/,
        mapper: (fields: JRSMParseResultField[]): ResultValue => {
          const month = fieldNamedValue('arrive_month', fields)
          const year = fieldNamedValue('arrive_year', fields)
          return fromMonthYear(month, year)
        }
      }
    ]
  },
  request_contributors: {
    ...jrsm2018.request_contributors,
    startRow: 72,
    endRow: 77,
  },
  request_funding: {
    ...jrsm2018.request_funding,
    startRow: 61,
    endRow: 64
  },
  request_not_targeted: {
    fields: []
  }

} as JRSMParserConfig;
